import React, { useState } from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Button from "../components/styles/Button"

export const pageQuery = graphql`
        query {
            allStrapiNoFound {
                edges {
                    node {
                        seo {
                            metaTitle
                            metaDescription
                            shareImage {
                              url
                            }
                        },
                        content {
                            title 
                            info
                            image {
                              localFile {
                                childImageSharp {
                                  gatsbyImageData(width: 1936,backgroundColor: "white", placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                                }
                              }
                            }
                        }
                        
                    }
                }
            }
        }`

export default function NotFoundPage({ data,location }) {

  const [headerColor,setHeaderColor] = useState("bg-white text-black")
  const node = data.allStrapiNoFound.edges[0].node

  return (
    <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
      <GatsbySeo
       title={node.seo.metaTitle}
       description={node.seo.metaDescription}
       
       openGraph={{
           url: location.href,
           title: node.seo.metaTitle,
           description: node.seo.metaDescription,
           images: [
             { url: node.seo.shareImage.url },
           ],
           site_name: 'sotto',
         }}
       />
      <div className="container mx-auto pt-[200px] pb-[510px] relative xl:flex-col xl:pt-[140px] xl:pb-[180px] sm:pt-24 sm:pb-20">
          <h1 className="text-[40px] leading-snug pb-[88px]">{node.content.title}</h1>
          <p className="text-3xl leading-snug pb-[60px] max-w-[380px]">{node.content.info}</p>
          <Button href="/" variant="blackB">返回主页</Button>
          <figure className="absolute bottom-0 right-[160px] w-[968px] xl:w-full xl:static xl:mt-14"><GatsbyImage className="w-full" image={getImage(node.content.image.localFile)} alt="404"/></figure>
          
      </div>
    </Layout>
  )
}